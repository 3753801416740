import PropTypes from '@root/vendor/prop-types';
import React, { useContext } from '@root/vendor/react';

const BillingFeaturesContext = React.createContext();

export default function BillingFeaturesContextProvider({
  children, billingFeaturesContext, onReloadBillingFeaturesContext,
}) {
  return (
    <BillingFeaturesContext.Provider
      value={{
        billingFeaturesContext,
        reloadBillingFeaturesContext: onReloadBillingFeaturesContext,
      }}
    >
      {children}
    </BillingFeaturesContext.Provider>
  );
}

BillingFeaturesContextProvider.propTypes = {
  billingFeaturesContext: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  onReloadBillingFeaturesContext: PropTypes.func.isRequired,
};

export function useBillingFeaturesContext() {
  const { billingFeaturesContext, reloadBillingFeaturesContext } = useContext(BillingFeaturesContext);

  return {
    billingFeaturesContext,
    reloadBillingFeaturesContext,
  };
}
