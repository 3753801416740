import AgeWhenLicensed from '@root/core/src/models/age-when-licensed';
import DobDate from '@root/core/src/models/dob-date';
import dayjs from '@root/vendor/dayjs';
import uuid from '@root/vendor/uuid/v4';
import { RootError } from '@root-common/root-errors';
import { obfuscate } from '@root/core/src/utils/strings';

export const MAX_YOUNG_DRIVER_AGE = 19;

export default class Driver {
  constructor() {
    this.accidentPreventionCourse = null;
    this.activeDutyMilitary = null;
    this.age;
    this.ageWhenLicensed;
    this.collegeGraduate = null;
    this.dob;
    this.driversLicenseId;
    this.firstName;
    this.gender;
    this.goodStudent = null;
    this.lastName;
    this.licenseNumber;
    this.licenseState;
    this.maritalStatus;
    this.nationalGuardMember = null;
    this.occasionalDriver = null;
    this.pni = false;
    this.refresherPreventionCourse = null;
    this.selected;
    this.universalDriverId = uuid();
    this.yearsLicensedSelection;
  }

  set(key, value) {
    return Object.assign(
      new Driver(),
      this,
      {
        [key]: value,
      },
    );
  }

  key() {
    return `${this.firstName} ${this.lastName} ${this.licenseNumber} ${this.universalDriverId}`;
  }

  fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  dobAge() {
    const now = dayjs();
    const dobDate = dayjs(this.dob.toStringUnobfuscate(), 'YYYY-MM-DD');
    return now.diff(dobDate, 'years');
  }

  obfuscatedLicenseNumber() {
    return obfuscate(this.licenseNumber, {
      numVisible: 3,
    });
  }

  valid() {
    return !!this.licenseNumber && !!this.licenseState;
  }

  static buildFromData(driverData) {
    const dob = new DobDate(driverData.dob);
    const age = driverData.age ?? dob.age();

    return Object.assign(
      new Driver(),
      {
        ...driverData,
        age,
        dob,
        universalDriverId: driverData.universalDriverId ? driverData.universalDriverId : uuid(),
        ageWhenLicensed: new AgeWhenLicensed(driverData.ageWhenLicensed),
      },
    );
  }

  determineLicenseValidationParams() {
    if (this.driversLicenseId && !this.isLicenseEdited) {
      return {
        driversLicenseId: this.driversLicenseId,
      };
    }

    return {
      licenseNumber: this.licenseNumber,
      licenseState: this.licenseState,
    };
  }

  buildLicenseParams() {
    if (this.driversLicenseId && !this.isLicenseEdited) {
      return {
        driversLicenseId: this.driversLicenseId,
      };
    }

    return {
      licenseNumber: this.licenseNumber,
      licenseState: this.licenseState,
    };
  }

  mergeDriver(newDriver) {
    if (this.matches(newDriver) || this.matchesUniversalDriverId(newDriver)) {
      return this.setAttributes({
        ...newDriver,
        universalDriverId: this.universalDriverId,
      });
    }

    throw new RootError({
      message: 'Attempted to merge incompatible drivers',
      name: 'DriverError',
    });
  }

  setAttributes(attributes = {}) {
    return Object.assign(
      new Driver(),
      this,
      attributes,
    );
  }

  matchesUniversalDriverId(driver) {
    return this.universalDriverId === driver.universalDriverId;
  }

  matches(driver) {
    const licenseObfuscated1 = obfuscate(this.licenseNumber?.toUpperCase(), {
      numVisible: 3,
    });
    const licenseObfuscated2 = obfuscate(driver.licenseNumber?.toUpperCase(), {
      numVisible: 3,
    });

    return this.firstName?.trim()?.toUpperCase() === driver.firstName?.trim()?.toUpperCase() &&
      this.lastName?.trim()?.toUpperCase() === driver.lastName?.trim()?.toUpperCase() &&
      licenseObfuscated1 === licenseObfuscated2;
  }

  serializeForSubmission() {
    return {
      ...this.buildLicenseParams(),
      accidentPreventionCourse: this.accidentPreventionCourse,
      activeDutyMilitary: this.activeDutyMilitary,
      age: this.age,
      ageWhenLicensed: AgeWhenLicensed.print(this.ageWhenLicensed),
      collegeGraduate: this.collegeGraduate,
      dob: this.dob.serializeForSubmission(),
      firstName: this.firstName?.trim(),
      gender: this.gender,
      goodStudent: this.goodStudent,
      lastName: this.lastName?.trim(),
      maritalStatus: this.maritalStatus,
      nationalGuardMember: this.nationalGuardMember,
      occasionalDriver: this.occasionalDriver,
      pni: this.pni,
      refresherPreventionCourse: this.refresherPreventionCourse,
      universalDriverId: this.universalDriverId,
      yearsLicensedSelection: this.yearsLicensedSelection,
    };
  }

  serializeForSaving() {
    return {
      ...this,
      ageWhenLicensed: AgeWhenLicensed.print(this.ageWhenLicensed),
      dob: this.dob.serializeForSubmission(),
      firstName: this.firstName?.trim(),
      lastName: this.lastName?.trim(),
      yearsLicensedSelection: this.yearsLicensedSelection,
    };
  }
}
