import ServerUtils from '@root/core/src/api/server-utils';

export async function getTerritoryContext(market) {
  const { data } = await ServerUtils.authenticatedGet('/web_api/territory_contexts/', {
    expectedResponse: [200],
    query: {
      market,
    },
  });

  return data;
}

export async function getMarketContext() {
  const { data } = await ServerUtils.authenticatedGet('/web_api/markets', {
    expectedResponse: [200],
  });

  return data;
}

export async function profileAddressVerificationConfiguration({
  city, state, zip, line1, line2,
}) {
  const { data } = await ServerUtils.authenticatedPost('/web_api/address_verification', {
    body: {
      prefill_request: {
        mailing_address: {
          city,
          state,
          zip,
          line1,
          line2,
          country: null,
        },
      },
    },
    expectedResponse: [200, 201],
  });

  return data;
}
