import Driver from '@root/auto-pricing/src/models/driver';
import ProfileDriverVehicleAssignmentService from '@root/auto-pricing/src/services/profile-driver-vehicle-assignment-service';
import Vehicle from '@root/core/src/models/vehicle';
import { RootError } from '@root-common/root-errors';

export default class DriverVehicleAssignment {
  static VALID_KEYS = {
    primaryVehicle: false,
    driver: null,
    vehicle: null,
  }

  constructor({
    driver, vehicle, primaryVehicle = true,
  }) {
    this.primaryVehicle = primaryVehicle;
    this.driver = driver;
    this.vehicle = vehicle;
  }

  static buildFromData({
    universalDriverId,
    vehicleId,
    primaryVehicle,
    drivers,
    vehicles,
  }) {
    const driver = drivers.find((d) => d.universalDriverId === universalDriverId);
    const vehicle = vehicles.find((v) => v.cid === vehicleId);
    const { ASSIGNMENT_STATUS } = ProfileDriverVehicleAssignmentService;

    const dva = new DriverVehicleAssignment({
      driver,
      vehicle,
      primaryVehicle,
    });

    const status = dva.status({
      drivers,
      vehicles,
    });
    if (status !== ASSIGNMENT_STATUS.VALID) {
      const errorMessageProlog = (errorStatus) => `Unable to construct a valid driver vehicle assignment from data with status ${errorStatus}.`;

      switch (status) {
      case ASSIGNMENT_STATUS.UNKNOWN_DRIVER:
        throw new RootError({
          message: `${errorMessageProlog(status)} Received universalDriverId: ${universalDriverId}, with available driver id's ${drivers.map((d) => d.universalDriverId)}.`,
          name: 'DriverVehicleAssignmentError',
          fingerprint: ['DriverVehicleAssignmentUnknownDriver'],
        });
      case ASSIGNMENT_STATUS.UNKNOWN_VEHICLE:
        throw new RootError({
          message: `${errorMessageProlog(status)} Received vehicleId: ${vehicleId}, with available vehicle id's ${vehicles.map((v) => v.cid)}.`,
          name: 'DriverVehicleAssignmentError',
          fingerprint: ['DriverVehicleAssignmentUnknownVehicle'],
        });
      case ASSIGNMENT_STATUS.UNKNOWN_PRIMARY_VEHICLE_STATUS:
        throw new RootError({
          message: `${errorMessageProlog(status)} Received primaryVehicle: ${primaryVehicle}.`,
          name: 'DriverVehicleAssignmentError',
          fingerprint: ['DriverVehicleAssignmentUnknownPrimaryVehicleStatus'],
        });
      default:
        throw new RootError({
          message: 'Unexpected status',
          name: 'DriverVehicleAssignmentError',
        });
      }
    }

    return dva;
  }

  set(key, value) {
    const merge = [
      new DriverVehicleAssignment({}),
      this,
    ];

    if (Object.prototype.hasOwnProperty.call(DriverVehicleAssignment.VALID_KEYS, key)) {
      merge.push({
        [key]: value,
      });
    }
    return Object.assign(...merge);
  }

  isValid({ vehicles, drivers }) {
    return this.status({
      vehicles,
      drivers,
    }) === ProfileDriverVehicleAssignmentService.ASSIGNMENT_STATUS.VALID;
  }

  status({ vehicles, drivers }) {
    const {
      driver, vehicle, primaryVehicle,
    } = this;

    const { ASSIGNMENT_STATUS } = ProfileDriverVehicleAssignmentService;

    if (!(driver instanceof Driver)) {
      return ASSIGNMENT_STATUS.UNKNOWN_DRIVER;
    }

    if (!(vehicle instanceof Vehicle)) {
      return ASSIGNMENT_STATUS.UNKNOWN_VEHICLE;
    }

    if (typeof primaryVehicle !== 'boolean') {
      return ASSIGNMENT_STATUS.UNKNOWN_PRIMARY_VEHICLE_STATUS;
    }

    if (!drivers.includes(driver)) {
      return ASSIGNMENT_STATUS.UNKNOWN_DRIVER;
    }

    if (!vehicles.includes(vehicle)) {
      return ASSIGNMENT_STATUS.UNKNOWN_VEHICLE;
    }

    return ASSIGNMENT_STATUS.VALID;
  }

  serializeForSubmission() {
    return {
      primaryVehicle: this.primaryVehicle,
      universalDriverId: this.driver?.universalDriverId || '',
      vehicleCid: this.vehicle?.cid || '',
      vin: this.vehicle?.vin || '',
      shortVin: this.vehicle?.shortVin || '',
    };
  }
}
