import MarketContext from '@root/auto-pricing/src/models/market-context';
import NetworkService from '@root/core/src/services/network-service';
import TerritoryContext from '@root/auto-pricing/src/models/territory-context';
import { getMarketContext, getTerritoryContext, profileAddressVerificationConfiguration } from '@root/profile-review/src/api/root-server';

export default class ProfileReviewService {
  static async getMarketContext() {
    const result = await NetworkService.safeRequest(getMarketContext);
    if (result.isError) { return result; }
    return new MarketContext(result.data.marketContext);
  }

  static async getTerritoryContext(market) {
    const result = await NetworkService.safeRequest(getTerritoryContext, market);

    if (result.isError) { return result; }

    return new TerritoryContext(result.data.territoryContext);
  }

  static isValidTerritory(territory, territories) {
    return territories.map((t) => t.toLowerCase()).includes(territory.toLowerCase());
  }

  static async addressVerification(mailingAddress) {
    const result = await NetworkService.safeRequest(profileAddressVerificationConfiguration, mailingAddress);
    if (result.isError) { return result; }

    return result._data;
  }
}
