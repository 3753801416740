import DriverVehicleAssignment from '@root/auto-pricing/src/models/driver-vehicle-assignment';
import has from '@root/vendor/lodash/has';
import { RootError } from '@root-common/root-errors';

const createDriverNotFoundError = (universalDriverId) =>
  new RootError({
    message: `No driver found with universal id ${universalDriverId}`,
    name: 'DriverVehicleMapError',
    fingerprint: ['DriverVehicleMapNoDriverFound'],
  });

const createDriverNotAssociatedError = (universalDriverId) =>
  new RootError({
    message: `Attempted to dissociated driver with universal id ${universalDriverId}. This driver was not associated with any vehicle`,
    name: 'DriverVehicleMapError',
    fingerprint: ['DriverVehicleMapDriverNotAssociated'],
  });

export default class DriverVehicleMap {
  static DEFAULTS = {
    associations: {},
  };

  static CHANGE = {
    ASSOCIATION: 'association',
    DISSOCIATION: 'dissociation',
    REASSIGN: 'reassign',
  }

  constructor({ associations } = DriverVehicleMap.DEFAULTS) {
    this.associations = associations;
  }

  toggleDriverVehicleAssociation(universalDriverId, vehicle, primaryVehicle) {
    const mapped = this.associations[universalDriverId];

    return mapped?.cid === vehicle.cid
      ? this.dissociateDriver(universalDriverId)
      : this.associateDriverToVehicle(universalDriverId, vehicle, primaryVehicle);
  }

  associateDriverToVehicle(universalDriverId, vehicle, primaryVehicle) {
    const hasDriver = has(
      this.associations,
      universalDriverId
    );

    if (!hasDriver) {
      throw createDriverNotFoundError(universalDriverId);
    }

    const newAssociations = {
      ...this.associations,
      [universalDriverId]: {
        cid: vehicle.cid,
        primaryVehicle,
      },
    };

    return this.setAssociations(newAssociations);
  }

  dissociateDriver(universalDriverId) {
    const hasDriver = has(
      this.associations,
      universalDriverId
    );

    const {
      [universalDriverId]: removed,
      ...remainingAssociations
    } = this.associations;

    if (!hasDriver) {
      throw createDriverNotFoundError(universalDriverId);
    }

    if (!removed) {
      throw createDriverNotAssociatedError(universalDriverId);
    }

    const newAssociations = {
      ...remainingAssociations,
      [universalDriverId]: null,
    };

    return this.setAssociations(newAssociations);
  }

  buildDriverVehicleAssignments(drivers, vehicles) {
    return Object.entries(this.associations).map(([universalDriverId, { cid, primaryVehicle }]) => {
      const vehicle = vehicles.find((v) => v.cid === cid);
      const driver = drivers.find((d) => d.universalDriverId === universalDriverId);

      return new DriverVehicleAssignment({
        driver,
        vehicle,
        primaryVehicle,
      });
    });
  }

  allDriversAssigned() {
    return Object.values(this.associations).every((cid) => cid);
  }

  driversAssigned() {
    return Object.values(this.associations).reduce((prev, cid) => {
      return cid ? prev + 1 : prev;
    }, 0);
  }

  change(oldMap) {
    const delta = Object.values(this.associations).filter((x) => x).length
    - Object.values(oldMap.associations).filter((x) => x).length;

    switch (delta) {
    case -1:
      return DriverVehicleMap.CHANGE.DISSOCIATION;
    case 0:
      return DriverVehicleMap.CHANGE.REASSIGN;
    case 1:
      return DriverVehicleMap.CHANGE.ASSOCIATION;
    default:
      throw new RootError({
        message: 'Mapping association should not change by more than 1',
        name: 'DriverVehicleMapError',
      });
    }
  }

  setAssociations(value) {
    return Object.assign(
      new DriverVehicleMap(),
      this,
      {
        associations: value,
      },
    );
  }
}
