export default class MarketContext {
  constructor(data) {
    this.excludedDriversMarkets = data?.excludedDriversMarkets ?? [];
    this.garagingAddressWebEnabled = data?.garagingAddressWebEnabled ?? false;
  }

  isGaragingAddressEnabled() {
    return this.garagingAddressWebEnabled;
  }

  isInExcludedDriversMarket(market) {
    return this.excludedDriversMarkets.some((enabledMarket) => enabledMarket.abbreviation === market);
  }
}
