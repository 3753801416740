import OpenOnPhone from '@root/splash/src/scenes/open-on-phone';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import { FunnelStages } from '@root/core/src/models/funnel-stages';
import { Route, Switch } from '@root/core/src/components/route';

const routeDefinition = {
  '/': OpenOnPhone,
  '/splash': OpenOnPhone,
};

export default class SplashRouter extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    passProps: PropTypes.object.isRequired,
  }

  _sceneRenderer(Scene, queryStringProps = {}) {
    return () => (
      <Scene
        {...this.props.passProps}
        {...queryStringProps}
      />
    );
  }

  render() {
    return (
      <Switch location={this.props.location}>
        {Object.keys(routeDefinition).map((route) => {
          return (
            <Route
              exact={true}
              FunnelStages={FunnelStages.SPLASH}
              key={route}
              path={route}
              render={this._sceneRenderer(routeDefinition[route])}
            />
          );
        })}
      </Switch>
    );
  }
}
