import DriverVehicleMap from '@root/auto-pricing/src/models/driver-vehicle-map';
import VehicleDriverMap from '@root/auto-pricing/src/models/vehicle-driver-map';
import isEqual from '@root/vendor/lodash/isEqual';
import uniqWith from '@root/vendor/lodash/uniqWith';

const selected = (obj) => obj.selected;

export default class ProfileDriverVehicleAssignmentService {
  static ASSIGNMENT_STATUS = {
    VALID: 'valid',
    UNKNOWN_DRIVER: 'unknownDriver',
    UNKNOWN_VEHICLE: 'unknownVehicle',
    TOO_MANY_ASSIGNMENTS: 'tooManyAssignments',
    UNKNOWN_PRIMARY_VEHICLE_STATUS: 'unknownPrimaryVehicleStatus',
    DUPLICATE_ASSIGNMENTS: 'duplicateAssignments',
  }

  static buildDriverVehicleMap(profileParams) {
    const { driverVehicleAssignments, drivers } = profileParams;

    const associations = drivers
      .filter(selected)
      .reduce((newMapping, driver) => {
        const driverVehicleAssignment = driverVehicleAssignments
          .find((dva) => dva.driver === driver);

        if (driverVehicleAssignment) {
          return newMapping;
        }

        return {
          ...newMapping,
          [driver.universalDriverId]: null,
        };
      }, {});

    return new DriverVehicleMap({
      associations,
    });
  }

  static buildVehicleDriverMap(profileParams) {
    const { driverVehicleAssignments, vehicles } = profileParams;

    const associations = vehicles
      .filter(selected)
      .reduce((newMapping, vehicle) => {
        const driverVehicleAssignment = driverVehicleAssignments
          .find((dva) => dva.vehicle === vehicle);

        if (driverVehicleAssignment) {
          return newMapping;
        }

        return {
          ...newMapping,
          [vehicle.cid]: null,
        };
      }, {});

    return new VehicleDriverMap({
      associations,
    });
  }

  static mergeDriverVehicleMap({ profileParams, driverVehicleMap }) {
    const {
      vehicles, drivers, driverVehicleAssignments,
    } = profileParams;

    const newDriverVehicleAssignments = [
      ...driverVehicleAssignments,
      ...driverVehicleMap.buildDriverVehicleAssignments(drivers, vehicles),
    ];

    return updateDriverVehicleAssignments(
      profileParams,
      newDriverVehicleAssignments
    );
  }

  static mergeVehicleDriverMap({ profileParams, vehicleDriverMap }) {
    const {
      vehicles, drivers, driverVehicleAssignments,
    } = profileParams;

    const newDriverVehicleAssignments = [
      ...driverVehicleAssignments,
      ...vehicleDriverMap.buildDriverVehicleAssignments(drivers, vehicles),
    ];

    return updateDriverVehicleAssignments(
      profileParams,
      newDriverVehicleAssignments
    );
  }

  static removeDriverByUniversalDriverId(profileParams, universalDriverId) {
    return updateDriverVehicleAssignments(
      profileParams,
      profileParams.driverVehicleAssignments.filter((dva) => dva.driver.universalDriverId !== universalDriverId)
    );
  }

  static removeVehicleByCid(profileParams, cid) {
    return updateDriverVehicleAssignments(
      profileParams,
      profileParams.driverVehicleAssignments.filter((dva) => dva.vehicle.cid !== cid)
    );
  }

  static clearAssignments(profileParams) {
    return updateDriverVehicleAssignments(
      profileParams,
      []
    );
  }

  static updateVinInAssignmentsForVehicle(profileParams, newVin) {
    const updatedVehicle = profileParams.vehicles.find(({ vin }) => {
      return vin === newVin;
    });
    const otherAssignments = profileParams.driverVehicleAssignments
      .filter(({ vehicle }) => vehicle.cid !== updatedVehicle.cid);

    const updatedAssignments = profileParams.driverVehicleAssignments
      .filter(({ vehicle }) => vehicle.cid === updatedVehicle.cid)
      .map((assignment) => {
        return assignment.set('vehicle', assignment.vehicle.set('vin', newVin).set('shortVin', ''));
      });

    return profileParams.set('driverVehicleAssignments', [...updatedAssignments, ...otherAssignments]);
  }

  static areValidAssignments(profileParams) {
    const {
      vehicles, drivers, driverVehicleAssignments,
    } = profileParams;

    for (const dva of driverVehicleAssignments) {
      const status = dva.status({
        drivers,
        vehicles,
      });

      if (status !== ProfileDriverVehicleAssignmentService.ASSIGNMENT_STATUS.VALID) {
        return status;
      }
    }

    const maxAssignments = Math.max(vehicles.length, drivers.length);
    if (driverVehicleAssignments.length > maxAssignments) {
      return ProfileDriverVehicleAssignmentService.ASSIGNMENT_STATUS.TOO_MANY_ASSIGNMENTS;
    }

    const uniqueDriverVehicleAssignments = uniqWith(driverVehicleAssignments.map((dva) => {
      return {
        universalDriverId: dva.driver.universalDriverId,
        vin: dva.vehicle.vin,
      };
    }), isEqual);

    if (uniqueDriverVehicleAssignments.length !== driverVehicleAssignments.length) {
      return ProfileDriverVehicleAssignmentService.ASSIGNMENT_STATUS.DUPLICATE_ASSIGNMENTS;
    }

    return ProfileDriverVehicleAssignmentService.ASSIGNMENT_STATUS.VALID;
  }
}

const updateDriverVehicleAssignments = (profileParams, newDriverVehicleAssignments) => {
  return profileParams.set('driverVehicleAssignments', newDriverVehicleAssignments);
};
