import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import phone from '@root/brand/src/assets/devices.png';
import rootLogo from '@root/brand/src/assets/root-icon.svg';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { regular, semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';

export default function OpenOnPhone() {
  return (
    <>
      <div css={styles.splash}>
        <div css={styles.imageContainer}>
          <img
            css={styles.logo}
            src={rootLogo}
          />
          <h1 css={styles.heading}>{'Open that link on your phone to continue.'}</h1>
          <p css={styles.subtext}>{'We\'ll need you to give this another try on your phone instead.'}</p>
        </div>
        <div css={styles.textContainer}>
          <img
            css={styles.phoneImage}
            src={phone}
          />
        </div>
      </div>
    </>
  );
}

const styles = StyleSheet.create({
  splash: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    ...Responsive.lg({
      flexDirection: 'row-reverse',
    }),
  },
  imageContainer: {
    marginTop: 26,
    marginLeft: 0,
    ...Responsive.md({
      marginTop: 110,
    }),
    ...Responsive.lg({
      marginTop: 0,
    }),
  },
  textContainer: {
    textAlign: 'center',
    ...Responsive.lg({
      marginTop: '5%',
      marginRight: 75,
      textAlign: 'right',
    }),
  },
  logo: {
    height: 45,
    width: 45,
    ...Responsive.md({
      height: 45,
      width: 45,
    }),
  },
  heading: {
    ...semiBold(),
    fontFamily: 'Root Sans',
    marginTop: 14,
    letterSpacing: '-0.48px',
    fontSize: 32,
    lineHeight: '32px',
    maxWidth: 322,
    ...Responsive.md({
      letterSpacing: '-0.5px',
      fontSize: 32,
      maxWidth: 375,
    }),
  },
  subtext: {
    ...regular(),
    color: Colors.gray50(),
    fontSize: 18,
    letterSpacing: '-0.25px',
    lineHeight: '26px',
    marginBottom: 45,
    marginTop: 15,
    maxWidth: 309,
    ...Responsive.md({
      fontSize: 20,
      letterSpacing: '-0.2px',
      lineHeight: '24px',
      marginTop: 21,
      maxWidth: 418,
    }),
  },
  phoneImage: {
    width: '90%',
    ...Responsive.md({
      margin: '0 auto',
    }),
    ...Responsive.lg({
      width: 'auto',
    }),
  },
});
