export default class DriverVehicleAssignmentFlowService {
  static getRoute({
    baseRoute,
    unassignedDriversCount,
    unassignedVehiclesCount,
    submitRoute = undefined,
  }) {
    if (unassignedDriversCount === 0 && unassignedVehiclesCount === 0) {
      return submitRoute;
    }

    if (unassignedDriversCount === 0) {
      return `${baseRoute}/driver-vehicle-assignment/vehicle-driver`;
    }

    return `${baseRoute}/driver-vehicle-assignment/driver-vehicle`;
  }
}
