export default class TerritoryContext {
  constructor({ territories, isCity }) {
    this.territories = territories.map((territory) => {
      let words = territory.split(' ');
      words = words.map((word) => word[0].toUpperCase() + word.slice(1));
      return words.join(' ');
    });
    this.isCity = isCity;
  }

  isSuccess = true
}
