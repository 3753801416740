import ProfileVehicleService from '@root/auto-pricing/src/services/profile-vehicle-service';
import { MAX_YOUNG_DRIVER_AGE } from '@root/auto-pricing/src/models/driver';
import { PRIMARY_USAGES, PRIMARY_USAGE_PROPS, VEHICLE_CLASS_CODE_PRIMARY_USAGES } from '@root/core/src/models/vehicle';

const ROUTE_NOT_FOUND = -1;

const {
  annualizedMileage,
  commuteDistance,
  editingMileage,
  primaryUsage,
  removed,
  testDriveSelected,
  weeklyCommuteDays,
} = PRIMARY_USAGE_PROPS;
export default class VehicleUsageFlowService {
  static getRouteOnSubmit({
    profileRulesContext,
    profileParams,
    baseRoute,
    submitRoute,
  }) {
    if (profileRulesContext.vehicleUsage) {
      if (profileRulesContext.vehicleClassCode) {
        return VehicleUsageFlowService.getRouteForVehicleClassCode(
          profileParams.vehicles,
          baseRoute,
          submitRoute,
        );
      }
      return VehicleUsageFlowService.getRouteForVehicleUsage(
        profileParams.vehicles,
        profileParams.drivers,
        baseRoute,
        submitRoute,
      );
    }

    return submitRoute;
  }

  static getRouteForVehicleUsageChange(profileParams, profileRulesContext, baseRoute, submitRoute) {
    if (profileRulesContext.vehicleUsage) {
      if (profileRulesContext.vehicleClassCode) {
        return VehicleUsageFlowService.getRouteForVehicleClassCode(
          profileParams.vehicles,
          baseRoute,
          submitRoute
        );
      }
      return VehicleUsageFlowService.getRouteForVehicleUsage(
        profileParams.vehicles,
        profileParams.drivers,
        baseRoute,
        submitRoute,
      );
    }
  }

  static getRouteForVehicleUsage(vehicles, drivers, baseRoute, submitRoute) {
    if (vehicles.every((vehicle) => vehicle.removed)) {
      return baseRoute;
    }

    let index = vehicles.findIndex((vehicle) => {
      return vehicle[primaryUsage] &&
        !vehicle[removed] &&
        !vehicle[annualizedMileage];
    });
    if (index !== ROUTE_NOT_FOUND) {
      return `${baseRoute}/vehicle-usage/vehicle-usage-${vehicles[index][primaryUsage]}/${index}`;
    }

    index = vehicles.findIndex((vehicle) => {
      return vehicle[primaryUsage] &&
        vehicle[annualizedMileage] &&
        vehicle[editingMileage];
    });
    if (index !== ROUTE_NOT_FOUND) {
      return `${baseRoute}/vehicle-usage/vehicle-usage-${PRIMARY_USAGES.occasional}/${index}`;
    }

    index = vehicles.findIndex((vehicle) => {
      return vehicle[primaryUsage] &&
        vehicle[annualizedMileage] &&
        vehicle[testDriveSelected] === null;
    });
    if (index !== ROUTE_NOT_FOUND) {
      return `${baseRoute}/vehicle-usage/vehicle-usage-review/${index}`;
    }

    index = ProfileVehicleService.getNextVehicleIndexWithoutPrimaryUsage(vehicles);
    if (index !== ROUTE_NOT_FOUND) {
      return `${baseRoute}/vehicle-usage/vehicle-usage-select-usage/${index}`;
    }

    if (drivers.some((d) => d.age <= MAX_YOUNG_DRIVER_AGE && d.selected && d.occasionalDriver === null)) {
      return `${baseRoute}/vehicle-usage/vehicle-usage-occasional-driver`;
    }

    return submitRoute;
  }

  static getRouteForVehicleClassCode(vehicles, baseRoute, submitRoute) {
    if (vehicles.every((vehicle) => vehicle.removed)) {
      return baseRoute;
    }

    let index = vehicles.findIndex((vehicle) => {
      return vehicle[primaryUsage] &&
        vehicle[primaryUsage] === VEHICLE_CLASS_CODE_PRIMARY_USAGES.work &&
        !vehicle[removed] &&
        vehicle[commuteDistance] &&
        !vehicle[weeklyCommuteDays];
    });
    if (index !== ROUTE_NOT_FOUND) {
      return `${baseRoute}/vehicle-usage/vehicle-usage-weekly-commute-days/${index}`;
    }

    index = vehicles.findIndex((vehicle) => {
      return vehicle[primaryUsage] &&
        vehicle[primaryUsage] === VEHICLE_CLASS_CODE_PRIMARY_USAGES.work &&
        !vehicle[removed] &&
        !vehicle[commuteDistance];
    });
    if (index !== ROUTE_NOT_FOUND) {
      return `${baseRoute}/vehicle-usage/vehicle-usage-${vehicles[index][primaryUsage]}/${index}`;
    }

    index = ProfileVehicleService.getNextVehicleIndexWithoutPrimaryUsage(vehicles);
    if (index !== ROUTE_NOT_FOUND) {
      return `${baseRoute}/vehicle-usage/vehicle-usage-select-usage/${index}`;
    }

    return submitRoute;
  }
}
