import AuthService from '@root/core/src/services/auth-service';
import DriverVehicleAssignmentFlowService from '@root/driver-vehicle-assignment/src/services/driver-vehicle-assignment-flow-service';
import ProfileDriverService from '@root/auto-pricing/src/services/profile-driver-service';
import ProfileReviewService from '@root/profile-review/src/services/profile-review-service';
import VehicleUsageFlowService from '@root/vehicle-usage/src/services/vehicle-usage-flow-service';

export const SUBMIT_ROUTE = '/profile-review/submit';
export const CREATE_ACCOUNT_ROUTE_SUFFIX = '/profile-review/create-account';
export const AGENTS_MODULE = 'client-profile';
export const AGENTS_SUBMIT_ROUTE = '/client-quote/loading';
export const QUOTES_MODULE = 'quotes';
export const BOOMERANG_MODULE = 'boomerang';
export const BOOMERANG_SUBMIT_ROUTE = '/quotes';
export const RIDESHARE_SUBROUTE = '/rideshare';

export default class ProfileReviewFlowService {
  static getRouteForEditAddress(module) {
    return `/${module}/profile-review/edit-address`;
  }

  static getRouteForEditDriver(module, driver) {
    return `/${module}/profile-review/edit-driver/${driver.universalDriverId}`;
  }

  static getRouteForEditExcludedDriver(module, excludedDriver) {
    return `/${module}/profile-review/edit-excluded-driver/${excludedDriver.id}`;
  }

  static getRouteForEditVehicle(module, vehicle) {
    return `/${module}/profile-review/edit-vehicle/${vehicle.cid}`;
  }

  static getRouteForReview(module) {
    return `/${module}/profile-review`;
  }

  static getRouteForCreateAccount(module) {
    return `/${module}${CREATE_ACCOUNT_ROUTE_SUFFIX}`;
  }

  static getRouteForMunicipalitySelector(module) {
    return `/${module}/profile-review/municipality-selector`;
  }

  static getRouteForAddressChangeNotAllowed(module) {
    return `/${module}/profile-review/address-change-not-allowed`;
  }

  static getRouteForRideshare(module) {
    return `/${module}/profile-review/rideshare`;
  }

  static getRouteForRootReady(module) {
    return `/${module}/profile-review/root-ready`;
  }

  static getRouteOnSubmit({
    module,
    profileRulesContext,
    profileParams,
    territoryContext,
  }) {
    if (profileRulesContext.vehicleUsage) {
      if (profileRulesContext.vehicleClassCode) {
        return ProfileReviewFlowService.getRouteForVehicleClassCode(
          module,
          profileParams.vehicles,
        );
      }
      return ProfileReviewFlowService.getRouteForVehicleUsage(
        module,
        profileParams.vehicles,
        profileParams.drivers,
        profileParams.driverVehicleAssignments
      );
    } else if (profileRulesContext.driverVehicleAssignments) {
      return ProfileReviewFlowService.getRouteForDriverVehicleAssignment(
        module,
        profileParams.unassignedDrivers().length,
        profileParams.unassignedVehicles().length,
      );
    } else if (territoryContext?.isCity
      && !ProfileReviewService.isValidTerritory(profileParams.mailingAddress.city, territoryContext.territories)
      && !profileParams.ratingMunicipality) {
      return this.getRouteForMunicipalitySelector(module);
    } else if (module === BOOMERANG_MODULE) {
      return BOOMERANG_SUBMIT_ROUTE;
    } else if (module === AGENTS_MODULE) {
      return AGENTS_SUBMIT_ROUTE;
    } else if (module !== QUOTES_MODULE && !AuthService.getCurrentUserContext().email) {
      return ProfileReviewFlowService.getRouteForCreateAccount(module);
    }

    return SUBMIT_ROUTE;
  }

  static getRouteForProfileChange(module, profileParams, profileRulesContext, pathname = '') {
    if (ProfileDriverService.requiresAgeLicensed(profileParams.drivers, profileRulesContext)) {
      return `/${module}/profile-review/age-licensed`;
    }

    if (pathname && pathname.includes(RIDESHARE_SUBROUTE)) {
      return this.getRouteForRideshare(module);
    }

    return this.getRouteForReview(module);
  }

  static getRouteForVehicleUsageChange(module, profileParams, profileRulesContext) {
    if (profileRulesContext.driverVehicleAssignments) {
      return ProfileReviewFlowService.getRouteForDriverVehicleAssignment(
        module,
        profileParams.unassignedDrivers().length,
        profileParams.unassignedVehicles().length,
      );
    } else if (profileRulesContext.vehicleUsage) {
      if (profileRulesContext.vehicleClassCode) {
        return ProfileReviewFlowService.getRouteForVehicleClassCode(
          module,
          profileParams.vehicles,
        );
      }
      return ProfileReviewFlowService.getRouteForVehicleUsage(
        module,
        profileParams.vehicles,
        profileParams.drivers,
        profileParams.driverVehicleAssignments
      );
    }
  }

  static getRouteForVehicleUsage(module, vehicles, drivers, driverVehicleAssignments) {
    const baseVehicleUsageRoute = `/${module}/profile-review`;

    const nextRoute = VehicleUsageFlowService.getRouteForVehicleUsage(vehicles, drivers, baseVehicleUsageRoute, SUBMIT_ROUTE);

    if (nextRoute === SUBMIT_ROUTE) {
      if (vehicles.some((v) => v.testDriveSelected) && (!driverVehicleAssignments || driverVehicleAssignments.length < drivers.length)) {
        return `${baseVehicleUsageRoute}/driver-vehicle-assignment/driver-vehicle`;
      }

      if (module === BOOMERANG_MODULE) {
        return BOOMERANG_SUBMIT_ROUTE;
      }

      if (module === AGENTS_MODULE) {
        return AGENTS_SUBMIT_ROUTE;
      }

      if (!AuthService.getCurrentUserContext().email) {
        return ProfileReviewFlowService.getRouteForCreateAccount(module);
      }
    }

    return nextRoute;
  }

  static getRouteForVehicleClassCode(module, vehicles) {
    const baseVehicleUsageRoute = `/${module}/profile-review`;

    const nextRoute = VehicleUsageFlowService.getRouteForVehicleClassCode(vehicles, baseVehicleUsageRoute, SUBMIT_ROUTE);

    if (nextRoute === SUBMIT_ROUTE) {
      if (module === BOOMERANG_MODULE) {
        return BOOMERANG_SUBMIT_ROUTE;
      }

      if (module === AGENTS_MODULE) {
        return AGENTS_SUBMIT_ROUTE;
      }

      if (!AuthService.getCurrentUserContext().email) {
        return ProfileReviewFlowService.getRouteForCreateAccount(module);
      }
    }

    return nextRoute;
  }

  static getRouteForDriverVehicleAssignment(module, unassignedDriversCount, unassignedVehiclesCount) {
    const baseRoute = `/${module}/profile-review`;

    const nextRoute = DriverVehicleAssignmentFlowService.getRoute({
      baseRoute,
      unassignedDriversCount,
      unassignedVehiclesCount,
    });

    if (nextRoute) {
      return nextRoute;
    }

    if (module === BOOMERANG_MODULE) {
      return BOOMERANG_SUBMIT_ROUTE;
    }

    if (module === AGENTS_MODULE) {
      return AGENTS_SUBMIT_ROUTE;
    }

    if (!AuthService.getCurrentUserContext().email) {
      return ProfileReviewFlowService.getRouteForCreateAccount(module);
    }

    return SUBMIT_ROUTE;
  }
}
