import ProfileDriverVehicleAssignmentService from '@root/auto-pricing/src/services/profile-driver-vehicle-assignment-service';
import { RootError } from '@root-common/root-errors';

export default class ProfileVehicleService {
  static getVehicle(profileParams, cid) {
    return profileParams.vehicles.find((vehicle) => vehicle.cid === cid);
  }

  static removeVehicle(profileParams, cid) {
    const updatedProfileParams = ProfileDriverVehicleAssignmentService.removeVehicleByCid(profileParams, cid);
    return updatedProfileParams.set('vehicles', updatedProfileParams.vehicles.filter((vehicle) => vehicle.cid !== cid));
  }

  static setVehicleValue(profileParams, cid, field, value) {
    return profileParams.set('vehicles', profileParams.vehicles.map((vehicle) => vehicle.cid === cid ? vehicle.set(field, value) : vehicle));
  }

  static setVehicleValues(profileParams, cid, values) {
    return profileParams.set('vehicles', profileParams.vehicles.map((vehicle) => {
      if (vehicle.cid !== cid) {
        return vehicle;
      }

      return vehicle.setAttributes(values);
    }));
  }

  static addVehicle(profileParams, vehicle) {
    let merged = false;

    const updatedVehicles = profileParams.vehicles.map((v) => {
      if (v.vin !== vehicle.vin || (!v.vin || !vehicle.vin) && v.cid !== vehicle.cid) {
        return v;
      }

      if (v.selected) {
        throw new RootError({
          message: 'Attempted to add duplicate vehicle',
          name: 'ProfileVehicleServiceError',
        });
      } else {
        merged = true;
        return v.mergeVehicle(vehicle);
      }
    });

    if (!merged) {
      updatedVehicles.push(vehicle);
    }

    return profileParams.set('vehicles', updatedVehicles);
  }

  static getNextVehicleIndexWithoutPrimaryUsage(vehicles) {
    return vehicles.findIndex((vehicle) => vehicle.selected && !vehicle.primaryUsage);
  }
}
