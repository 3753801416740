import AnalyticsService from '@root/core/src/services/analytics-service';
import AttributionService from '@root/attribution/src/services/attribution-service';
import BillingFeaturesContext from '@root/auto-pricing/src/models/billing-features-context';
import BillingFeaturesContextProvider from '@root/auto-pricing/src/hooks/use-billing-features-context';
import BoomerangContextProvider from '@root/boomerang/src/hooks/use-boomerang-context';
import CurrentUserContext from '@root/account/src/contexts/current-user/current-user-context';
import PolicyContext from '@root/auto-pricing/src/models/policy-context';
import PolicyContextProvider from '@root/auto-pricing/src/hooks/use-policy-context';
import PropTypes from '@root/vendor/prop-types';
import ProtectedRouter from '@root/account.joinroot.com/src/components/protected-router';
import React, { useCallback, useEffect, useRef, useState } from '@root/vendor/react';
import SceneLoader from '@root/core/src/components/scene-loader';
import environment from '@root/core/src/utils/environment';
import getBillingFeaturesContextConfiguration from '@root/auto-pricing/src/api/get-billing-features-context/get-billing-features-context-configuration';
import getBoomerangContextConfiguration from '@root/boomerang/src/api/get-boomerang-context-configuration';
import getCurrentUserContextConfiguration from '@root/user-session/src/api/get-current-user-context/get-current-user-context-configuration';
import getPolicyContextConfiguration from '@root/auto-pricing/src/api/get-policy-context/get-policy-context-configuration';
import getWebQuoteLoginLinkConfiguration from '@root/account.joinroot.com/src/api/get-web-quote-login-link/get-web-quote-login-link-configuration';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import validateTokenConfiguration from '@root/auth/src/api/validate-token/validate-token-configuration';

export default function ProtectedApp({ passProps, pingInterval = 10000 }) {
  const { trackEvent } = useAnalytics('PROTECTED_APP');

  const [fetchBoomerangContext] = useImperativeNetworkRequest(getBoomerangContextConfiguration);
  const [fetchCurrentUser] = useImperativeNetworkRequest(getCurrentUserContextConfiguration);
  const [fetchPolicyContext] = useImperativeNetworkRequest(getPolicyContextConfiguration);
  const [fetchBillingFeaturesContext] = useImperativeNetworkRequest(getBillingFeaturesContextConfiguration);
  const [validateToken] = useImperativeNetworkRequest(validateTokenConfiguration);
  const [getWebQuoteLoginLink] = useImperativeNetworkRequest(getWebQuoteLoginLinkConfiguration);

  const [boomerangContext, setBoomerangContext] = useState();
  const [currentUserContext, setCurrentUserContext] = useState();
  const [policyContext, setPolicyContext] = useState();
  const [billingFeaturesContext, setBillingFeaturesContext] = useState();
  const [isReady, setIsReady] = useState(false);
  const [hasAliasedUser, setHasAliasedUser] = useState(false);

  const isMounted = useRef(false);

  const fullName = currentUserContext ? `${currentUserContext.firstName} ${currentUserContext.lastName}` : '';

  const fetchUserContext = useCallback(async () => {
    const result = await fetchCurrentUser();

    if (isMounted.current) {
      if (result.isSuccess()) {
        setCurrentUserContext(result.data.currentUserContext);
      } else {
        throw Error('Could not fetch current user');
      }
    }
  }, [fetchCurrentUser]);

  const getBoomerangContext = useCallback(async () => {
    if (policyContext?.canceled) {
      if (environment.isBoomerangEnabled) {
        const result = await fetchBoomerangContext();

        if (result?.isSuccess() && result?.data?.boomerangContext?.eligibleForRequote) {
          setBoomerangContext(result.data.boomerangContext);
          return;
        }
      }
    }

    setBoomerangContext({
      eligibleForRequote: false,
    });
  }, [fetchBoomerangContext, policyContext]);

  const getPolicyContext = useCallback(async () => {
    const result = await fetchPolicyContext();

    if (isMounted.current) {
      if (result?.isSuccess()) {
        const builtPolicyContext = PolicyContext.buildFromData(result.data.policyContext);

        setPolicyContext(builtPolicyContext);
        return;
      }
      trackEvent('REDIRECTED_TO_QUOTE');

      const loginLinkResult = await getWebQuoteLoginLink();
      passProps.onLogout();

      window.location.assign(loginLinkResult.data.loginLink); /* eslint root/prevent-use-of-window-location: 0 */
    }
  }, [fetchPolicyContext, getWebQuoteLoginLink, passProps, trackEvent]);

  const getBillingFeaturesContext = useCallback(async () => {
    if (isMounted.current) {
      if (policyContext) {
        const result = await fetchBillingFeaturesContext();

        if (result?.isSuccess()) {
          const builtBillingFeaturesContext = BillingFeaturesContext.buildFromData(result.data.billingFeaturesContext);

          setBillingFeaturesContext(builtBillingFeaturesContext);
        }
      }
    }
  }, [fetchBillingFeaturesContext, policyContext]);

  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  });

  useEffect(() => {
    if (!isReady) {
      AttributionService.associateVisitsToUser();
    }
  }, [isReady]);

  useEffect(() => {
    if (!currentUserContext) {
      fetchUserContext();
    }
  }, [currentUserContext, fetchUserContext]);

  useEffect(() => {
    if (currentUserContext && !hasAliasedUser) {
      AnalyticsService.alias(currentUserContext.userId);
      setHasAliasedUser(true);
    }
  }, [currentUserContext, hasAliasedUser]);

  useEffect(() => {
    if (!policyContext) {
      getPolicyContext();
    }
  }, [policyContext, getPolicyContext]);

  useEffect(() => {
    if (!billingFeaturesContext && policyContext) {
      getBillingFeaturesContext();
    }
  }, [policyContext, billingFeaturesContext, getBillingFeaturesContext]);

  useEffect(() => {
    if (!boomerangContext && policyContext) {
      getBoomerangContext();
    }
  }, [policyContext, boomerangContext, getBoomerangContext]);

  useEffect(() => {
    if (currentUserContext && boomerangContext && policyContext && billingFeaturesContext && isMounted.current) {
      setIsReady(true);
    }
  }, [billingFeaturesContext, currentUserContext, policyContext, isMounted, boomerangContext]);

  useEffect(() => {
    const pingForToken = async () => {
      const result = await validateToken();

      if (!result.data.valid && isMounted.current) {
        trackEvent('LOGOUT_DUE_TO_INVALID_TOKEN');
        passProps.onLogout();
      }
    };

    const interval = setInterval(pingForToken, pingInterval);

    return () => clearInterval(interval);
  }, [passProps, pingInterval, trackEvent, validateToken]);

  if (isReady) {
    return (
      <CurrentUserContext.Provider
        value={{
          ...currentUserContext,
          fullName,
        }}
      >
        <PolicyContextProvider
          onReloadPolicyContext={getPolicyContext}
          policyContext={policyContext}
        >
          <BillingFeaturesContextProvider
            billingFeaturesContext={billingFeaturesContext}
            onReloadBillingFeaturesContext={getBillingFeaturesContext}
          >
            <BoomerangContextProvider
              boomerangContext={boomerangContext}
              onReloadBoomerangContext={getBoomerangContext}
            >
              <ProtectedRouter passProps={passProps} />
            </BoomerangContextProvider>
          </BillingFeaturesContextProvider>
        </PolicyContextProvider>
      </CurrentUserContext.Provider>
    );
  }

  return <SceneLoader />;
}

ProtectedApp.propTypes = {
  passProps: PropTypes.object.isRequired,
  pingInterval: PropTypes.number,
};
