import DriverVehicleAssignment from '@root/auto-pricing/src/models/driver-vehicle-assignment';
import has from '@root/vendor/lodash/has';
import { RootError } from '@root-common/root-errors';

const createVehicleNotFoundError = (vehicleCid) =>
  new RootError({
    message: `No vehicle found with cid ${vehicleCid}`,
    name: 'VehicleDriverMapError',
    fingerprint: ['VehicleDriverMapNoVehicleFound'],
  });

const createVehicleNotAssociatedError = (vehicleCid) =>
  new RootError({
    message: `Attempted to dissociated vehicle with cid ${vehicleCid}. This vehicle was not associated with any driver`,
    name: 'VehicleDriverMapError',
    fingerprint: ['VehicleDriverMapVehicleNotAssociated'],
  });

export default class VehicleDriverMap {
  static DEFAULTS = {
    associations: {},
  };

  static CHANGE = {
    ASSOCIATION: 'association',
    DISSOCIATION: 'dissociation',
    REASSIGN: 'reassign',
  }

  constructor({ associations } = VehicleDriverMap.DEFAULTS) {
    this.associations = associations;
  }

  toggleVehicleDriverAssociation(vehicleCid, driver, primaryVehicle) {
    const mapped = this.associations[vehicleCid];

    return mapped?.universalDriverId === driver.universalDriverId
      ? this.dissociateVehicle(vehicleCid)
      : this.associateVehicleToDriver(vehicleCid, driver, primaryVehicle);
  }

  associateVehicleToDriver(vehicleCid, driver, primaryVehicle) {
    const hasVehicle = has(
      this.associations,
      vehicleCid
    );

    if (!hasVehicle) {
      throw createVehicleNotFoundError(vehicleCid);
    }

    const newAssociations = {
      ...this.associations,
      [vehicleCid]: {
        universalDriverId: driver.universalDriverId,
        primaryVehicle,
      },
    };

    return this.setAssociations(newAssociations);
  }

  dissociateVehicle(vehicleCid) {
    const hasVehicle = has(
      this.associations,
      vehicleCid
    );

    const {
      [vehicleCid]: removed,
      ...remainingAssociations
    } = this.associations;

    if (!hasVehicle) {
      throw createVehicleNotFoundError(vehicleCid);
    }

    if (!removed) {
      throw createVehicleNotAssociatedError(vehicleCid);
    }

    const newAssociations = {
      ...remainingAssociations,
      [vehicleCid]: null,
    };

    return this.setAssociations(newAssociations);
  }

  buildDriverVehicleAssignments(drivers, vehicles) {
    return Object.entries(this.associations).map(([vehicleCid, { universalDriverId, primaryVehicle }]) => {
      const driver = drivers.find((d) => d.universalDriverId === universalDriverId);
      const vehicle = vehicles.find((v) => v.cid === vehicleCid);

      return new DriverVehicleAssignment({
        driver,
        vehicle,
        primaryVehicle,
      });
    });
  }

  allVehiclesAssigned() {
    return Object.values(this.associations).every((universalDriverId) => !!universalDriverId);
  }

  vehiclesAssigned() {
    return Object.values(this.associations).reduce((prev, universalDriverId) => {
      return universalDriverId
        ? prev + 1
        : prev;
    }, 0);
  }

  change(oldMap) {
    const delta = Object.values(this.associations).filter((x) => x).length
    - Object.values(oldMap.associations).filter((x) => x).length;

    switch (delta) {
    case -1:
      return VehicleDriverMap.CHANGE.DISSOCIATION;
    case 0:
      return VehicleDriverMap.CHANGE.REASSIGN;
    case 1:
      return VehicleDriverMap.CHANGE.ASSOCIATION;
    default:
      throw new RootError({
        message: 'Mapping association should not change by more than 1',
        name: 'VehicleDriverMapError',
      });
    }
  }

  setAssociations(value) {
    return Object.assign(
      new VehicleDriverMap(),
      this,
      {
        associations: value,
      },
    );
  }
}
