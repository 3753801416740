import dayjs from '@root/vendor/dayjs';

export default class BillingFeaturesContext {
  constructor({
    nextPaymentDate,
    upcomingBillingAmountInDollars,
    paymentStatus,
    nonPaymentCancellationDate,
    upcomingRepayments,
    isInLateFeeGracePeriod,
    lateFeeAmountInCents,
    renewalPaymentCharges,
    lateFeeAssessDate,
    upcomingPaymentBreakdown,
  } = {}) {
    this.nextPaymentDate = nextPaymentDate;
    this.upcomingBillingAmountInDollars = upcomingBillingAmountInDollars;
    this.paymentStatus = paymentStatus;
    this.nonPaymentCancellationDate = nonPaymentCancellationDate;
    this.upcomingRepayments = upcomingRepayments;
    this.renewalPaymentCharges = renewalPaymentCharges;
    this.isInLateFeeGracePeriod = isInLateFeeGracePeriod;
    this.lateFeeAmountInCents = lateFeeAmountInCents;
    this.lateFeeAssessDate = lateFeeAssessDate;
    this.upcomingPaymentBreakdown = upcomingPaymentBreakdown;
  }

  static buildFromData({
    nextPaymentDate,
    upcomingBillingAmountInDollars,
    paymentStatus,
    nonPaymentCancellationDate,
    upcomingRepayments,
    renewalPaymentCharges,
    isInLateFeeGracePeriod,
    lateFeeAmountInCents,
    lateFeeAssessDate,
    upcomingPaymentBreakdown,
  } = {}) {
    return Object.assign(
      new BillingFeaturesContext(),
      {
        nextPaymentDate,
        upcomingBillingAmountInDollars,
        paymentStatus,
        nonPaymentCancellationDate,
        upcomingRepayments,
        renewalPaymentCharges,
        isInLateFeeGracePeriod,
        lateFeeAmountInCents,
        lateFeeAssessDate,
        upcomingPaymentBreakdown,
      },
    );
  }

  static isSingleRepaymentOverdue = ({ isOverdue }) => isOverdue;

  isPaymentOverdue() {
    return this.paymentStatus === 'late';
  }

  isAnyPaymentOverdue() {
    return this.isPaymentOverdue() || this.isAnyRepaymentOverdue();
  }

  isAnyRepaymentOverdue() {
    return this.upcomingRepayments.some(BillingFeaturesContext.isSingleRepaymentOverdue);
  }

  overdueRepayments() {
    return this.upcomingRepayments.filter(BillingFeaturesContext.isSingleRepaymentOverdue);
  }

  firstNonPaymentCancellationDate() {
    const repaymentNonPaymentCancellationDates = this.upcomingRepayments.map(({ nonPaymentCancellationDate }) => nonPaymentCancellationDate);

    return repaymentNonPaymentCancellationDates.concat([this.nonPaymentCancellationDate]).sort((a, b) => {
      const dayjsA = dayjs(a);
      const dayjsB = dayjs(b);

      if (dayjsA.isBefore(dayjsB)) {
        return -1;
      } else if (dayjsA.isAfter(dayjsB)) {
        return 1;
      } else {
        return 0;
      }
    })[0];
  }

  firstRenewalPayment() {
    if (this.renewalPaymentCharges) {
      return this.renewalPaymentCharges[0];
    }
  }
}
