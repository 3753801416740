import ProfileDriverVehicleAssignmentService from '@root/auto-pricing/src/services/profile-driver-vehicle-assignment-service';
import { RootError } from '@root-common/root-errors';

export default class ProfileDriverService {
  static isDriverMaritalStatusValid(profileParams, universalDriverId) {
    const driver = ProfileDriverService.getDriver(profileParams, universalDriverId);
    return !driver || !!driver.maritalStatus;
  }

  static requiresAgeLicensed(drivers, profileRules) {
    return ProfileDriverService.requiresAgeWhenLicensed(drivers, profileRules)
      || ProfileDriverService.requiresInexperiencedOperator(drivers, profileRules);
  }

  static getNextDriverAgeWhenLicensedIndex(drivers) {
    return drivers.findIndex((driver) => driver.selected && (!driver.ageWhenLicensed || !driver.ageWhenLicensed.isValid()));
  }

  static getNextAmbiguousInexperiencedOperatorIndex(drivers) {
    return drivers.findIndex((driver) => {
      if (!driver.selected || driver.yearsLicensedSelection) { return false; }
      if (!driver.ageWhenLicensed) { return false; }
      if (!driver.ageWhenLicensed?.isValid?.()) { return false; }

      const age = driver.dobAge();
      const yearsLicensed = age - driver.ageWhenLicensed;

      // 0 years licensed is not captured, because it is not ambiguous due to the guarantee that
      // the driver must have less than one year of experience if they are still the same age
      // as when they got their license
      return yearsLicensed <= 3 && yearsLicensed > 0;
    });
  }

  static requiresAgeWhenLicensed(drivers, profileRules) {
    return profileRules.ageWhenLicensed && this.getNextDriverAgeWhenLicensedIndex(drivers) !== -1;
  }

  static requiresInexperiencedOperator(drivers, profileRules) {
    return profileRules.inexperiencedOperator && this.getNextAmbiguousInexperiencedOperatorIndex(drivers) !== -1;
  }

  static getDriver(profileParams, universalDriverId) {
    return profileParams.drivers.find((driver) => driver.universalDriverId === universalDriverId);
  }

  static getDriverByIndex(profileParams, index) {
    return profileParams.drivers[index];
  }

  static getOrderedDriverByIndex(profileParams, index) {
    return profileParams.getOrderedDrivers()[index];
  }

  static setIsLicenseEdited(editedDriver, originalDriver) {
    if (editedDriver.isLicenseEdited || editedDriver.licenseNumber !== originalDriver.licenseNumber || editedDriver.licenseState !== originalDriver.licenseState) {
      editedDriver.isLicenseEdited = true;
    } else {
      editedDriver.isLicenseEdited = false;
    }
  }

  static setDriverValue(profileParams, universalDriverId, field, value) {
    return profileParams.set('drivers', profileParams.drivers.map((driver) =>
      driver.universalDriverId === universalDriverId ? driver.set(field, value) : driver));
  }

  static togglePNI(profileParams, universalDriverId) {
    return profileParams.set('drivers', profileParams.drivers.map((driver) => {
      return driver.universalDriverId === universalDriverId || driver.pni
        ? driver.set('pni', !driver.pni)
        : driver;
    }));
  }

  static toggleDriverValue(profileParams, universalDriverId, field) {
    return profileParams.set('drivers', profileParams.drivers.map((driver) =>
      driver.universalDriverId === universalDriverId ? driver.set(field, !driver[field]) : driver));
  }

  static addDriver(profileParams, driver) {
    profileParams.drivers.forEach((d) => {
      if (!d.matches(driver)) {
        return;
      }

      if (d.selected) {
        throw new RootError({
          message: 'Attempted to add driver with duplicate first name, last name and license number',
          name: 'ProfileDriverServiceError',
        });
      } else {
        driver = d.mergeDriver(driver);
        profileParams = ProfileDriverService.removeDriver(profileParams, d.universalDriverId);
      }
    });

    const newProfileParams = profileParams.set('drivers', [...profileParams.drivers, driver]);
    return ProfileDriverService.removeAnyMatchingExcludedDriver(newProfileParams, driver);
  }

  static removeDriver(profileParams, universalDriverId) {
    const updatedProfileParams = ProfileDriverVehicleAssignmentService.removeDriverByUniversalDriverId(profileParams, universalDriverId);
    return updatedProfileParams.set('drivers', updatedProfileParams.drivers.filter((d) => d.universalDriverId !== universalDriverId));
  }

  static removeAnyMatchingExcludedDriver(profileParams, driver) {
    const newExcludedDrivers = profileParams.excludedDrivers.filter((excludedDriver) => !excludedDriver.matches(driver));
    return profileParams.set('excludedDrivers', newExcludedDrivers);
  }
}
