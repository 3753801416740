import AbTestProvider from '@root/core/src/contexts/ab-test/ab-test-provider';
import AnalyticsProvider from '@root/core/src/contexts/analytics/analytics-provider';
import AnalyticsService from '@root/core/src/services/analytics-service';
import AuthController from '@root/auth/src/components/auth-controller';
import ErrorBoundary from '@root/core/src/components/error-boundary';
import ExperienceProvider from '@root/core/src/contexts/experience/experience-provider';
import PropTypes from '@root/vendor/prop-types';
import ProtectedApp from '@root/account.joinroot.com/src/components/protected-app';
import PublicRouter from '@root/account.joinroot.com/src/components/public-router';
import SceneLoader from '@root/core/src/components/scene-loader';
import ScrollToTop from '@root/core/src/components/scroll-to-top';
import TrackingService from '@root/attribution/src/services/tracking-service';
import environment from '@root/core/src/utils/environment';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { RootIdService } from '@root/attribution/src/services/root-id-service';
import { Suspense, useEffect } from '@root/vendor/react';
import { datadogRum } from '@root/vendor/@datadog/browser-rum';

const ACCOUNT_APP_ANALYTICS_PREFIX = 'ACCOUNT_APP';
const ACCOUNT_APP_DOMAIN = 'account.joinroot.com';

if (environment.dataDogEnabled) {
  datadogRum.init({
    applicationId: environment.dataDogApplicationId,
    clientToken: environment.dataDogClientToken,
    site: 'datadoghq.com',
    service: 'account.joinroot.com',
    env: environment.environment,
    version: environment.releaseSha,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [/https?:\/\/localhost(:\d+)?/, /https?:\/\/(.+\.)?joinroot.com(:\d+)?/],
    trackSessionAcrossSubdomains: true,
  });

  // eslint-disable-next-line root/prevent-use-of-window-location
  const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
  datadogRum.addRumGlobalContext('urlParameters', urlParams);

  const rootId = RootIdService.read();
  datadogRum.addRumGlobalContext('rootId', rootId);

  datadogRum.startSessionReplayRecording();
}

function App({ routerComponent: Router }) {
  useAnalytics('APP_ENTRY');

  useEffect(() => {
    TrackingService.fireDistinctIdSetEvent(AnalyticsService.getDistinctId());
  }, []);

  return (
    <Router>
      <ScrollToTop>
        <Suspense fallback={<SceneLoader />}>
          <AuthController
            passProps={{
              isAccountApp: true,
            }}
            ProtectedApp={ProtectedApp}
            PublicRouter={PublicRouter}
            SceneLoader={SceneLoader}
          />
        </Suspense>
      </ScrollToTop>
    </Router>
  );
}

App.propTypes = {
  routerComponent: PropTypes.func.isRequired,
};

export default function AppEntry({
  initialAbTests = {}, initialExperiences = {}, routerComponent,
}) {
  return (
    <ErrorBoundary>
      <AbTestProvider initialAbTests={initialAbTests}>
        <ExperienceProvider initialExperiences={initialExperiences}>
          <AnalyticsProvider eventPrefix={ACCOUNT_APP_ANALYTICS_PREFIX}>
            <App routerComponent={routerComponent} />
          </AnalyticsProvider>
        </ExperienceProvider>
      </AbTestProvider>
    </ErrorBoundary>
  );
}

AppEntry.propTypes = {
  initialAbTests: PropTypes.object,
  initialExperiences: PropTypes.object,
  routerComponent: PropTypes.func.isRequired,
};

export { ACCOUNT_APP_ANALYTICS_PREFIX, ACCOUNT_APP_DOMAIN };
